<template>
    <div>
        <router-link to="/" class="come-back">Вернуться в раздел “Общественная экспертиза”</router-link>
        <div class="white-container">
            <p class="white-container__date">{{dateArr(document.date)}}</p>
            <h5>Законопроект на обсуждении</h5>
            <documents-info :content="document" class="container__document"/>
        </div>
        <div class="white-container comment">
            <p class="comment__title">Комментарии к законопроекту</p>
            <comment-new-bill v-for="item of comment" :card="item" :key="item.id"/>
        </div>
    </div>
</template>
<script>
import DocumentsInfo from '@/components/DocumentsInfo'
import CommentNewBill from '@/components/activitiesSurveys/CommentNewBill'

export default {
  name: 'NewBill',
  components: {
    'documents-info': DocumentsInfo,
    'comment-new-bill': CommentNewBill
  },
  data () {
    return {
      isActiveTab: 0,
      document: {
        date: '08.01.2020',
        title: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
        url: '/',
        size: 235,
        type: 'PDF'
      },
      comment: [
        {
          id: 1,
          text: '«Для нас, крымчан, да и для всех россиян это очень знаковое событие.  Мост позволит республике окончательно воссоединиться с Россией.  Мост позволит республике окончательно воссоединиться с Россией.',
          date: '08.19.2020',
          author: {
            photo: 'image2.png',
            firstName: 'Иван',
            lastName: 'Абажер'
          }
        },
        {
          id: 4,
          text: '«Для нас, крымчан, да и для всех россиян это очень знаковое событие. Мост позволит республике окончательно воссоединиться с Россией.',
          date: '08.19.2020',
          author: {
            photo: 'image2.png',
            firstName: 'Иван',
            lastName: 'Абажер'
          }
        },
        {
          id: 5,
          text: '«Для нас, крымчан, да и для всех россиян это очень знаковое событие. Мост позволит республике окончательно воссоединиться с Россией.',
          date: '08.19.2020',
          author: {
            photo: 'image2.png',
            firstName: 'Иван',
            lastName: 'Абажер'
          }
        },
        {
          id: 10,
          text: '«Для нас, крымчан, да и для всех россиян это очень знаковое событие. Мост позволит республике окончательно воссоединиться с Россией.',
          date: '08.19.2020',
          author: {
            photo: 'image2.png',
            firstName: 'Иван',
            lastName: 'Абажер'
          }
        }
      ]
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    }
  }
}
</script>
<style lang="scss" scoped>
.come-back {
  /*display: block;*/
  width: 85.37rem;
  margin: .875rem auto;
  font-size: .75rem;
  line-height: 1.38rem;
  color:#246CB7;
  display: flex;
  justify-content: flex-start;
}
.white-container{
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
    &__date{
        font-size: .875rem;
        line-height: 1.37rem;
        color: #5E686C;
        opacity: 0.8;
        margin-bottom: 1.19rem;
    }
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.container__document{
    margin-top: 2rem;
}
.comment{
    margin-top: 3.125rem;
    &__title{
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #1F3245;
        margin-bottom: 2rem;
    }
}
.comment :last-child{
    margin-bottom: 0;
}
@media screen and (max-width: 768px){
  .come-back {
    width: 90%;
  }
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .comment {
    .news-quote {
      height: unset;
    }
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
}
</style>
